import React from "react";

export default function Title() {
  return (
    <h3>
      <span>Berikut adalah Portofolio </span>
      <span>
        dari <span className={"highlight"}>12 Kategori Seragam </span>
      </span>
      <span>yang telah kami kerjakan.</span>
    </h3>
  );
}
