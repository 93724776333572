import React from "react";
import Background from "./HeaderHero/Background";
import Title from "./HeaderHero/Title";
import Lists from "./HeaderHero/Lists";
import { PortofolioState } from ".";
import { FluidObject } from "gatsby-image";
import { PortofolioData } from "src/types";

import styles from "./Portofolio.module.scss";

interface HeaderHeroProps {
  bg: FluidObject[];
  data: Omit<
    PortofolioData,
    "desktopImg1" | "mobileImg1" | "desktopImg2" | "mobileImg2"
  >;
  active: PortofolioState;
  setActive: React.Dispatch<PortofolioState>;
  setShow: React.Dispatch<boolean>;
}

const list: Omit<PortofolioState, "data">[] = [
  { key: "seragamPria", title: "Seragam Pria" },
  { key: "seragamWanita", title: "Seragam Wanita" },
  { key: "seragamLapangan", title: "Seragam Lapangan" },
  { key: "seragamSecurity", title: "Seragam Security" },
  { key: "seragamTeknisi", title: "Seragam Teknisi" },
  { key: "seragamHotel", title: "Seragam Hotel" },
  { key: "seragamRestauran", title: "Seragam Restauran" },
  { key: "seragamPabrik", title: "Seragam Pabrik" },
  { key: "seragamSpa", title: "Seragam Spa" },
  { key: "seragamPerawat", title: "Seragam Perawat" },
  { key: "seragamOtomotif", title: "Seragam Otomotif" },
  { key: "seragamKomunitas", title: "Seragam Komunitas" },
];

export default function HeaderHero({
  bg,
  data,
  active,
  setActive,
  setShow,
}: HeaderHeroProps) {
  return (
    <header className={styles.header}>
      <Background background={bg} />
      <div className={styles.titles}>
        <Title />
        <Lists
          active={active}
          setActive={setActive}
          setShow={setShow}
          data={data}
          list={list}
        />
      </div>
    </header>
  );
}
