import React from "react";
import Img, { FluidObject } from "gatsby-image";
import styles from "../Portofolio.module.scss";

type BackroundProps = { background: FluidObject[] };
export default function Background({ background }: BackroundProps) {
  return (
    <div className={styles.heroBackground}>
      <Img
        fluid={background}
        alt="portofolio-background"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
