import React from "react";
import { PortofolioData } from "src/types";
import { PortofolioState } from "../index";
import styles from "../Portofolio.module.scss";
import { getUserScreen } from "@helper/index";

type ListsProps = {
  data: Omit<
    PortofolioData,
    "desktopImg1" | "mobileImg1" | "desktopImg2" | "mobileImg2"
  >;
  list: Omit<PortofolioState, "data">[];
  active: PortofolioState;
  setActive: React.Dispatch<PortofolioState>;
  setShow: React.Dispatch<boolean>;
};

export default function Lists({
  data,
  list,
  active,
  setActive,
  setShow,
}: ListsProps) {
  /**
   * List Mouse event on click
   *
   * @param e React Mouse Event
   * @param state PortofolioState
   */
  const onClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    state: Omit<PortofolioState, "data">
  ) => {
    e.preventDefault();
    /** Data active when clicked */
    const activeData: PortofolioState = {
      data: data[state.key],
      key: state.key,
      title: state.title,
    };
    setActive(activeData);
    setShow(true);
  };

  const userScreenWidth = getUserScreen();
  const newList = [...list];
  const half = Math.ceil(newList.length / 2);

  const left = newList.splice(0, half);
  const right = newList.splice(-half);

  return (
    <div className={styles.lists}>
      <div className={styles.col}>
        {left.map((state: Omit<PortofolioState, "data">, index: number) => {
          const text =
            userScreenWidth <= 1023.98
              ? `${state.title}`
              : `${index + 1}. ${state.title}`;
          const activeClass = active.key === state.key ? "active" : undefined;

          return (
            <p
              onClick={e => onClick(e, state)}
              key={index}
              className={activeClass}
            >
              {text}
            </p>
          );
        })}
      </div>
      <div className={styles.col}>
        {right.map((state: Omit<PortofolioState, "data">, index: number) => {
          const text =
            userScreenWidth <= 1023.98
              ? `${state.title}`
              : `${index + left.length + 1}. ${state.title}`;
          const activeClass = active.key === state.key ? "active" : undefined;

          return (
            <p
              onClick={e => onClick(e, state)}
              key={index}
              className={activeClass}
            >
              {text}
            </p>
          );
        })}
      </div>
    </div>
  );
}
