import React from "react";
import { graphql } from "gatsby";
import { PortofolioPage } from "src/types";
import Portofolio from "@views/Portofolio";

/**
 * QUERIES FOR LAYANAN GRATIS PAGE
 *
 * Query graphql untuk get image pada folder asset
 * query ini digunakan untuk image fluid pada
 * gatsby-image
 */
export const pageQuery = graphql`
  query Portofolio {
    # QUERY DESKTOP IMAGE
    desktopImg1: file(relativePath: { eq: "bg/desktop/portofolio-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 910, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImg2: file(relativePath: { eq: "bg/desktop/home-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # QUERY MOBILE IMAGE
    mobileImg1: file(relativePath: { eq: "bg/mobile/portofolio-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileImg2: file(relativePath: { eq: "bg/mobile/home-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # SERAGAM PRIA
    seragamPria: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/pria)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM WANITA
    seragamWanita: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/wanita)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM LAPANGAN
    seragamLapangan: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/outdoor)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM SECURITY
    seragamSecurity: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/security)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM TEKNISI
    seragamTeknisi: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/teknisi)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM HOTEL
    seragamHotel: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/hotel)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM RESTAURAN
    seragamRestauran: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/restauran)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM PABRIK
    seragamPabrik: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/pabrik)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM SPA
    seragamSpa: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/spa)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM PERAWAT
    seragamPerawat: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/perawat)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM OTOMOTIF
    seragamOtomotif: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/otomotif)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM KOMUNITAS
    seragamKomunitas: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/komunitas)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # JAKET
    jaket: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/jaket)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # ROMPI
    rompi: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/rompi)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # POLO
    polo: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/polo)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # WEARPACK
    wearpack: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(portofolio/wearpack)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default function portofolio(props: PortofolioPage) {
  return <Portofolio {...props} />;
}
