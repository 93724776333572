import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { PortofolioState } from ".";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import Title from "./Gallery/Title";
import Galleries from "./Gallery/Galleries";
import styles from "./Portofolio.module.scss";
import { getUserScreen } from "@helper/index";

type GalleryProps = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  active: PortofolioState;
  show: boolean;
};

export default function Gallery({ active, show, setShow }: GalleryProps) {
  const [page, setPage] = useState<number>(0);
  const { title, data } = active;
  const userWidth = getUserScreen();
  const { pathname } = useLocation();
  const itemPerPage = userWidth <= 1023.98 ? 4 : 10;
  const isMainPorto = pathname.includes("seragam-kemeja");
  const contentClass = show
    ? `${styles.contents} ${isMainPorto ? `active` : `main-porto`}`
    : styles.contents;

  const onClick = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const PaginateProps: ReactPaginateProps = {
    previousLabel: null,
    nextLabel: null,
    breakLabel: "...",
    breakClassName: styles.breakPaginate,
    containerClassName: styles.containerPaginate,
    pageCount: Math.ceil(data.edges.length / itemPerPage),
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 3,
    onPageChange: onClick,
    pageClassName: styles.pagePaginate,
    activeClassName: "active",
  };

  return (
    <div className={contentClass}>
      <Title title={title} setShow={setShow} />
      <Galleries data={data} page={page} />
      <ReactPaginate {...PaginateProps} />
    </div>
  );
}
