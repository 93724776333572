import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import Layout from "@components/Layout";
import MainHeaderHero from "@views/Portofolio/HeaderHero";
import Gallery from "@views/Portofolio/Gallery";
import { PortofolioPage, PortofolioData, IMultiFile } from "src/types";

export type keyPortofolio = keyof Omit<
  PortofolioData,
  "desktopImg1" | "mobileImg1" | "desktopImg2" | "mobileImg2"
>;

export type PortofolioState = {
  key: keyPortofolio;
  title: string;
  data: IMultiFile;
};

export default function Portofolio(props: PortofolioPage) {
  const { key: pageKey, title: pageTitle } = props.pageContext;
  const {
    desktopImg1,
    mobileImg1,
    seragamPria,
  } = props.data;
  const [show, setShow] = useState<boolean>(false);
  const [active, setActive] = useState<PortofolioState>({
    key: "seragamPria",
    title: "Seragam Pria",
    data: seragamPria,
  });
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes("seragam-kemeja")) {
      if (pageKey) {
        setActive({
          data: props.data[pageKey as keyPortofolio],
          title: pageTitle,
          key: pageKey as keyPortofolio,
        });

        setShow(true);
      }
    }
  }, []);

  const bgMain = [
    mobileImg1.childImageSharp.fluid,
    {
      ...desktopImg1.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <Layout>
      {pathname.includes("seragam-kemeja") && (
        <MainHeaderHero
          bg={bgMain}
          active={active}
          data={props.data}
          setActive={setActive}
          setShow={setShow}
        />
      )}
      <Gallery setShow={setShow} active={active} show={show} />
    </Layout>
  );
}
