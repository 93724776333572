import React from "react";
import { useLocation } from "@reach/router";
import styles from "../Portofolio.module.scss";
import { getUserScreen } from "@helper/index";

type TitleProps = {
  title: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Title({ title, setShow }: TitleProps) {
  const userWidth = getUserScreen();
  const { pathname } = useLocation();
  const isMobile = userWidth <= 1023.98;
  const isMainPorto = pathname.includes("seragam-kemeja");

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <>
      {isMobile && isMainPorto ? (
        <button className={styles.title} onClick={onClick}>
          <div className={styles.img} />
        </button>
      ) : (
        <h4
          className={`${styles.title} ${
            isMobile && !isMainPorto ? `mobile` : ``
          }`}
        >
          Portofolio&nbsp;
          <span className={"highlight"}>{title}</span>
        </h4>
      )}
    </>
  );
}
