import React from "react";
import Img from "gatsby-image";
import { IMultiFile, IEdge } from "src/types";
import styles from "../Portofolio.module.scss";
import { getUserScreen } from "@helper/index";


type GalleriesProps = { data: IMultiFile; page: number };

export default function Galleries({ data, page }: GalleriesProps) {
  const { edges } = data;
  const userWidth = getUserScreen()
  const itemPerPage = userWidth <= 1023.98 ? 4 : 10
  const firstIndex = page * itemPerPage;
  

  let newEdges: IEdge[] = [];
  if (edges.length > 0) {
    newEdges = [...edges].slice(firstIndex, firstIndex + itemPerPage);
  }

  return (
    <div className={styles.container}>
      {newEdges.map(edge => {
        const { fluid } = edge.node.childImageSharp;
        return (
          <div key={edge.node.id} className={styles.item}>
            <Img
              fluid={fluid}
              alt="item-portofolio"
              imgStyle={{ objectPosition: 'center top' }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );
      })}
    </div>
  );
}
